export const BASE_URL = ''
export const PROPERTIES_URL = `/api/properties`
export const AGENTS_URL = `/api/agents` 
export const PARTNER_URL = `/api/partner`  
export const BLOGS_URL = `/api/blogs`
export const TRANSLATION_URL = `/api/translation`
export const USERS_URL = `/api/users`
export const USERS_LOGIN_GOOGLE = `/api/auth`
export const ORDERS_URL = `/api/orders`
export const PAYPAL_URL = `/api/config/paypal`
export const UPLOAD_URL = `/api/upload`
export const CONTACT_URL = `/api/form/contact`
export const MESSAGE_URL = `/api/messages`
export const WISHLIST_URL = `/api/wishlist`
